import React from 'react'
import { createContext, useState } from "react";

export const contextRegexObj = createContext({})

const RegexInputsValuesContext = ({children}) => {

    const baseLatitudeAndLongitudeRegex4cValues = (latitudeValue) => {

        let valueFormated = latitudeValue.replace(/\D/g, "")

        if(valueFormated.length > 6){
            valueFormated = valueFormated.replace(/^(\d{2})(\d{2})(\d{2})(\d{1,3})/, "-$1:$2:$3,$4")

        }else if(valueFormated.length >= 5 || valueFormated.length == 6){
            valueFormated = valueFormated.replace(/^(\d{2})(\d{2})(\d{1,2})/, "-$1:$2:$3")

        }else if(valueFormated.length > 2 || valueFormated.length == 4){
            valueFormated = valueFormated.replace(/^(\d{2})(\d{1,2})/, "-$1:$2")

        }else if(valueFormated.length == 1 || valueFormated.length < 3){
            valueFormated = valueFormated.replace(/^(\d{1,2})/, "-$1")
        }

        if (valueFormated.length > 12) {
            valueFormated = valueFormated.substr(0, 13);
        }

        return valueFormated

    }

    const baseLatitudeLongitudeDDCoordRegexValues = (latitudeValue) => {

        let valueFormated = latitudeValue.replace(/\D/g, "")

        if(valueFormated.length > 2){
            valueFormated = valueFormated.replace(/^(\d{2})(\d{0,10})/, "-$1,$2")

        }else if(valueFormated.length > 0  || valueFormated.length == 2){
            valueFormated = valueFormated.replace(/^(\d{1,2})/, "-$1")
        }

        if (valueFormated.length > 14) {
            valueFormated = valueFormated.substr(0, 14);
        }
        
        return valueFormated

    }

    const profundidadePerfuracaoAguaRegexValues = (waterDeepValue) => {

        let valueFormated = waterDeepValue.replace(/\D/g, "")

        if(valueFormated.length > 4 && valueFormated.length != 4){
            valueFormated = valueFormated.replace(/^(\d{4})(\d{1,2})/, "$1,$2")

        }else if(valueFormated.length == 4){
            valueFormated = valueFormated.replace(/^(\d{1,3})(\d{1})/, "$1,$2")

        }else if(valueFormated.length > 4){
            valueFormated = valueFormated.replace(/^(\d{4})(\d{1,2})/, "$1,$2")

        }else if(valueFormated.length <= 3){
            valueFormated = valueFormated.replace(/^(\d{0,3})/, "$1")

        }

        if (valueFormated.length > 7) {
            valueFormated = valueFormated.substr(0, 7);
        }
        
        return valueFormated

    }

    return (
        <contextRegexObj.Provider value={{
            baseLatitudeAndLongitudeRegex4cValues,
            baseLatitudeLongitudeDDCoordRegexValues,
            profundidadePerfuracaoAguaRegexValues
        }}>
            {children}
        </contextRegexObj.Provider>
    )

}

export default RegexInputsValuesContext