import { createContext, useState } from "react";
import React from "react"

export const createContextCadastro = createContext({})

const CadastrosContext = ({children}) => {

    const [treeSelectData, setTreeSelectData] = useState([])
    const [isStatusChanged, setIsStatusChanged] = useState(false)
    const [statusChanged, setStatusChanged] = useState()

    return (
        <createContextCadastro.Provider value={{
            treeSelectData, 
            setTreeSelectData,
            isStatusChanged, 
            setIsStatusChanged,
            statusChanged, 
            setStatusChanged
        }}>
            {children}
        </createContextCadastro.Provider>
    )

}

export default CadastrosContext