import React, { createContext, useState } from 'react'

export const instanceContext = createContext({})

const Instance = ({children}) => {
    const [selectedInstance, setSelectedInstance] = useState("")

    return (
        <instanceContext.Provider value={{selectedInstance, setSelectedInstance}}> 
            {children}
        </instanceContext.Provider>
    )
}

export default Instance