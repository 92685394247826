import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';
import RegexInputsValuesContext from './components/utility/regexFields/regexFileds';
import CadastrosContext from './context/cadastros';
import Instance from './context/instance';

const App = () => (
  <Provider store={store}>
    <AppProvider>
      <RegexInputsValuesContext>
        <CadastrosContext>
          <Instance>
            <GlobalStyles />
            <Routes />
          </Instance>
        </CadastrosContext>
      </RegexInputsValuesContext>
    </AppProvider>
  </Provider>
);
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
