export const PUBLIC_ROUTE = {
  LANDING: '/',
  SIGN_IN: '/signin/:name',
  SIGN_UP: '/signup',
  FORGET_PASSWORD: '/forgotpassword',
  RESET_PASSWORD: '/resetpassword',
  // WELLHANDOVER: '/dashboard/WellHandoverForm',
  PAGE_404: '/404',
  PAGE_500: '/500',
  AUTH0_CALLBACK: '/auth0loginCallback',
  ERROR_BOUNDARY: '/ErrorBoundary',
};

export const PRIVATE_ROUTE = {
  DASHBOARD: '/',
};
